<template>
  <div>
    <DataTable
      v-model:editingRows="editingRows"
      v-model:filters="rowFilters"
      :value="items"
      :row-hover="true"
      :loading="loading"
      :paginator="paginator"
      :rows="10"
      :scrollable="true"
      :lazy="true"
      :totalRecords="total"
      :rowsPerPageOptions="[10, 25, 50]"
      :showGridlines="showGridlines"
      filterDisplay="row"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      currentPageReportTemplate="Memuat {first} dari {last} data dari total {totalRecords}"
      class="p-datatable-sm default-row"
      :rowClass="rowClass"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      editMode="row"
      @row-edit-save="onRowEditSave"
      resizable-columns
      column-resize-mode="expand"
    >
      <template #empty>
        <div class="text-center">Tidak ada data.</div>
      </template>
      <template #loading>
        <div class="text-center">Memuat data, harap tunggu...</div>
      </template>
      <template v-if="hasHeader" #header>
        <slot name="header"></slot>
      </template>
      <slot name="columns"></slot>
      <template v-if="hasFooter" #footer>
        <slot name="footer"></slot>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { roundNumber } from '@/helpers/index'

export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    showGridlines: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    gridOptions: {
      type: Object,
      default() {
        return {}
      },
    },
    filters: {
      type: Object,
      default() {
        return {}
      },
    },
    hasHeader: {
      type: Boolean,
      default: false,
    },
    hasFooter: {
      type: Boolean,

      default: false,
    },
    paginator: {
      type: Boolean,
      default: true,
    },
    showRowClass: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rowFilters: this.filters,
      editingRows: [],
      options: this.gridOptions,
    }
  },
  methods: {
    onPage(e) {
      e.page = e.page + 1
      e.sortField = this.options.sortField
      e.sortOrder = this.options.sortOrder
      this.options = e
      this.options.filters = this.rowFilters
      this.loadData()
    },
    onSort(e) {
      this.options = e
      this.loadData()
    },
    onFilter() {
      this.options.filters = this.rowFilters
      this.options.page = 1
      this.loadData()
    },
    loadData() {
      this.$emit('request', this.options)
    },
    rowClass(data) {
      if (!this.showRowClass) {
        return ''
      }

      const price = roundNumber(data.price)
      const margin = roundNumber(data.margin)
      if (data.mark === 4) {
        return 'bg-yellow-100'
      }
      if (data.mark === 3) {
        return 'bg-green-100'
      }
      if (data.mark === 2 || price < margin) {
        return 'bg-blue-100'
      }
      if (data.mark === 1 || margin < price) {
        return 'bg-pink-100'
      }
      if (data.mark === 0) {
        return 'bg-gray'
      }
    },
    onRowEditSave(event) {
      let { newData, index } = event
      this.$emit('onRowEditSave', { newData, index })
    },
  },
}
</script>
<style>
.default-row .bg-gray {
  background-color: #f8f9fa;
}
</style>
