import http from '../clients/Axios'

export default class ItemService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          if (
            el === 'supplier' ||
            el === 'name' ||
            el === 'sku' ||
            el === 'barcode'
          ) {
            filters_param =
              filters_param + '&' + el + '=' + filters[el].value.trim()
          } else {
            filters_param = filters_param + '&' + el + '=' + filters[el].value
          }
        }
      })
    }

    const res = await http.get(
      `/item/paginate?page=${pageNumber}&size=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async getList(keyword = '') {
    const res = await http.get(`/item/list?keyword=${keyword}`)
    return res.data
  }

  async getListBySupplier(keyword = '', supp_id) {
    const res = await http.get(
      `/item/list?keyword=${keyword}&supp_id=${supp_id}`
    )
    return res.data
  }

  async getListCategory(keyword = '', ctg_id) {
    const res = await http.get(`/item/list?keyword=${keyword}&ctg_id=${ctg_id}`)
    return res.data
  }

  async getListPromo(keyword = '', supp_id) {
    const res = await http.get(
      `/item/promo?keyword=${keyword}&supp_id=${supp_id}`
    )
    return res.data
  }

  async get(params = '') {
    const res = await http.get(`/item${params}`)
    return res
  }

  async getById(param) {
    param = param ? param : ''
    const res = await http.get(`/item/${param}`)
    return res
  }

  async add(form) {
    const res = await http.post('/item', form)
    return res
  }

  async update(id, form) {
    const res = await http.put(`/item/${id}`, form)
    return res
  }

  async delete(item) {
    const res = await http.delete(`/item/${item}`)
    return res
  }

  async export() {
    return http({
      url: 'item/export',
      method: 'GET',
      responseType: 'blob',
    })
  }
}
