<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span class="pr-2 mr-2 font-bold text-2xl">Print Label Barcode</span>
      <div>
        <i class="pi pi-home mr-2"></i>
        <span class="font-small">/ Inventory / Print Label Barcode</span>
      </div>
    </div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          type="button"
          icon="pi pi-print"
          label="Print"
          class="mr-2"
          :loading="isLoadingPrint"
          @click="generateBarcode()"
        />
        <Button
          type="button"
          icon="pi pi-save"
          label="Save"
          :disabled="active === 0"
          class="p-button-secondary mr-2"
          @click="save()"
        />
        <Button
          type="button"
          icon="pi pi-refresh"
          label="Refresh"
          class="p-button-outlined"
          @click="clearFilter()"
        />
      </div>
      <TabView v-model:activeIndex="active" @tab-change="onTabChange()">
        <TabPanel header="Label Barcode dari Penerimaan">
          <div class="col-12">
            <div class="formgrid grid">
              <div class="field col-12 md:col-2">
                <label>No. Penerimaan</label>
                <AutoComplete
                  v-model="selectedReceive"
                  class="w-full"
                  input-class="w-full"
                  :suggestions="listReceive"
                  field="rcv_no"
                  v-on:clear="true"
                  @complete="searchReceive"
                  @item-select="selectReceive"
                >
                  <template #item="slotProps">
                    <div>
                      <b>{{ slotProps.item.rcv_no }}</b>
                    </div>
                    <div>
                      {{ formatDate(slotProps.item.rcv_at) }} |
                      {{ slotProps.item.supplier.name }}
                    </div>
                  </template>
                </AutoComplete>
              </div>
              <div class="field col-12 md:col-3">
                <label> &nbsp; </label>
                <InputText
                  v-model="form.supplier"
                  class="w-full"
                  disabled
                  input-class="w-full"
                />
              </div>
              <div class="field col-12 md:col-4 mt-5">
                <input
                  :checked="change"
                  type="checkbox"
                  @click="onChecked"
                /><label>Hanya Item dengan Perubahan Harga</label>
              </div>
              <div class="field col-12 md:col-3 mt-4">
                <Button
                  icon="pi pi-sync"
                  label="Load Data Penerimaan"
                  class="mr-2"
                  :disabled="!(form.id > 0)"
                  @click="loadItem"
                />
              </div>
            </div>
          </div>
          <div class="card">
            <fx-table
              :items="items"
              :total="items.length"
              :loading="isLoading"
              :paginator="false"
              hasFooter
              :row-class="rowClass"
              showGridlines
              showRowClass
            >
              <template #columns>
                <Column
                  field="rcv_at"
                  header="Tgl. RCV"
                  :showFilterMenu="false"
                  style="min-width: 6rem"
                  header-style="align-items: end"
                >
                  <template #body="{ data }">
                    {{ formatDate(data.rcv_at) }}
                  </template>
                </Column>
                <Column
                  field="description"
                  header="Deskripsi"
                  class="bg-gray"
                  style="min-width: 15rem"
                  header-style="align-items: end"
                />
                <Column
                  field="unit_b"
                  header="Satuan"
                  class="bg-gray"
                  header-style="align-items: end"
                >
                  <template #body="{ data }">
                    {{ data.unit_b }}.{{ data.unit_s }}
                  </template>
                </Column>
                <Column
                  field="ubox"
                  header="Isi"
                  class="bg-gray"
                  style="width: 2rem"
                  header-style="align-items: end"
                />
                <Column
                  field="qty_rcv"
                  header="Qty.&nbsp; RCV"
                  class="bg-gray"
                  style="min-width: 5rem"
                  header-style="align-items: end"
                />
                <Column
                  field="qty_stock"
                  header="Stock Qty"
                  class="bg-gray"
                  style="min-width: 5rem"
                  header-style="align-items: end"
                />
                <Column
                  field="cur_price"
                  header="HRG Jual SKRG"
                  class="bg-gray"
                  style="min-width: 8rem"
                  header-style="align-items: end"
                >
                  <template #body="{ data }">
                    {{ formatCurrency(data.cur_price) }}
                  </template>
                </Column>
                <Column
                  field="new_price"
                  header="HRG Jual Baru"
                  class="bg-gray"
                  style="min-width: 8rem"
                  header-style="align-items: end"
                >
                  <template #body="{ data }">
                    {{ formatCurrency(data.new_price) }}
                  </template>
                </Column>
                <Column
                  field="qty_label"
                  header="Qty. Label"
                  class="bg-gray"
                  style="min-width: 5rem"
                  header-style="align-items: end"
                >
                  <template #body="{ data }">
                    <InputNumber
                      v-model="data.qty_label"
                      type="text"
                      class="w-full"
                      input-class="w-full"
                    />
                  </template>
                </Column>
                <Column
                  field="description_label"
                  header="Keterangan"
                  class="bg-gray"
                  style="min-width: 12rem"
                  header-style="align-items: end"
                >
                  <template #body="{ data }">
                    <InputText
                      v-model="data.description_label"
                      type="text"
                      class="w-full"
                      input-class="w-full"
                      maxlength="20"
                    />
                  </template>
                </Column>
              </template>
            </fx-table>
          </div>
        </TabPanel>
        <TabPanel header="Label Barcode Manual">
          <div class="col-12">
            <div class="formgrid grid">
              <div class="field col-12 md:col-3">
                <label>Item</label>
                <AutoComplete
                  v-model="selectedItem"
                  class="w-full"
                  input-class="w-full"
                  :suggestions="listItems"
                  field="name"
                  @complete="searchItem"
                  @item-select="selectItem"
                >
                  <template #item="slotProps">
                    <div>
                      <b>{{ slotProps.item.sku }}</b>
                    </div>
                    <div>{{ slotProps.item.name }}</div>
                  </template>
                </AutoComplete>
              </div>
              <div class="field col-12 md:col-3">
                <label> Supplier </label>
                <Dropdown
                  v-model="formManual.supp_id"
                  :filter="true"
                  :options="list_supplier"
                  class="w-full"
                  input-class="w-full"
                  optionValue="id"
                  optionLabel="name"
                  placeholder="Pilih Supplier"
                />
              </div>
              <div class="field col-12 md:col-2 mt-4">
                <Button
                  icon="pi pi-plus"
                  label="Tambah Item"
                  class="w-full"
                  @click="onAddData"
                />
              </div>
              <div class="field col-12 md:col-2 mt-4">
                <Button
                  icon="pi pi-times-circle"
                  label="Clear All"
                  class="p-button-outlined"
                  @click="onReset"
                />
              </div>
            </div>
          </div>
          <div class="card">
            <fx-table
              :items="itemManuals"
              :total="itemManuals.length"
              :loading="isLoading"
              :paginator="false"
              hasFooter
              :row-class="rowClass"
              showGridlines
              showRowClass
            >
              <template #columns>
                <Column
                  field="sku"
                  header="Kode"
                  class="bg-gray"
                  style="min-width: 3rem"
                  header-style="align-items: end"
                />
                <Column
                  field="barcode_1"
                  header="Barcode"
                  class="bg-gray"
                  style="min-width: 8rem"
                  header-style="align-items: end"
                />
                <Column
                  field="description"
                  header="Deskripsi"
                  class="bg-gray"
                  style="min-width: 18rem"
                  header-style="align-items: end"
                >
                  <template #body="{ data }">
                    <InputText
                      v-model="data.description"
                      type="text"
                      class="w-full"
                      input-class="w-full"
                      maxlength="20"
                    />
                  </template>
                </Column>
                <Column
                  field="unit"
                  header="Satuan"
                  class="bg-gray"
                  header-style="align-items: end"
                >
                  <template #body="{ data }">
                    {{ data.unit_b }}.{{ data.unit_s }}
                  </template>
                </Column>
                <Column
                  field="ubox"
                  header="Isi"
                  class="bg-gray"
                  style="width: 2rem"
                  header-style="align-items: end"
                />
                <Column
                  field="qty_label"
                  header="Qty. Print"
                  class="bg-gray"
                  style="min-width: 3rem"
                  header-style="align-items: end"
                >
                  <template #body="{ data }">
                    <InputNumber
                      v-model="data.qty_label"
                      type="text"
                      class="w-full"
                      input-class="w-full"
                    />
                  </template>
                </Column>
                <Column
                  field="price"
                  header="Harga"
                  class="bg-gray"
                  style="min-width: 8rem"
                  header-style="align-items: end"
                >
                  <template #body="{ data }">
                    {{ formatCurrency(data.price) }}
                  </template>
                </Column>
                <Column
                  field="supplier"
                  header="Supplier"
                  class="bg-gray"
                  style="min-width: 12rem"
                  header-style="align-items: end"
                >
                </Column>
                <Column style="flex-grow: 1; justify-content: flex-end">
                  <template #body="{ data }">
                    <Button
                      type="button"
                      icon="pi pi-trash"
                      class="p-button-outlined p-button-danger p-button-text"
                      @click="onConfirmDeletion(data)"
                    />
                  </template>
                </Column>
              </template>
            </fx-table>
          </div>
        </TabPanel>
      </TabView>
    </div>
    <Dialog
      header="Hapus Item dari List"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Item <strong>{{ formManual.description }}</strong> akan dihapus dari
          List Item Barcode. Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteItem(this.rowdata)"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <Dialog
      :header="pdfTitle"
      v-model:visible="dialogViewer"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '90vw' }"
      :modal="true"
    >
      <div>
        <embed
          :src="pdfSource"
          type="application/pdf"
          width="100%"
          height="600px"
        />
      </div>
      <template #footer>
        <Button
          type="button"
          label="Download"
          icon="pi pi-download"
          class="p-button-text mr-2"
          @click="onDownloadPdf"
        />
        <Button
          type="button"
          label="Print"
          icon="pi pi-print"
          @click="onPrintPdf"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import SupplierService from '@/services/SupplierService'
import BarcodeService from '@/services/BarcodeService'
import errorHandler from '@/helpers/error-handler'
import Hotkey from '@/components/Hotkey'
import { formatCurrency, formatDate } from '@/helpers/index'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'

import FxTable from '@/components/FxTable'
import { useAuthStore } from '@/store/auth'

import { jsPDF } from 'jspdf'
import { saveAs } from 'file-saver'
import JsBarcode from 'jsbarcode'
import ItemService from '@/services/ItemService'

export default {
  name: 'PrintBarcode',
  components: {
    Hotkey,
    TabView,
    TabPanel,
    FxTable,
  },
  data() {
    return {
      index: 0,
      rowdata: {},
      isLoading: false,
      isLoadingSave: false,
      isLoadingPrint: false,
      barcodeService: null,
      listReceive: [],
      selectedReceive: null,
      form: {},
      formManual: {},
      items: [],
      itemManuals: [],
      itemService: null,
      supplierService: null,
      listItems: [],
      list_supplier: [],
      submitted: false,
      selectedItem: [],
      listDeleted: [],
      active: 0,
      change: false,
      totalRecords: 0,
      options: {
        page: 1,
        rows: 10,
        sortField: '',
        sortOrder: '1',
        filters: null,
      },
      pdfSource: '',
      pdfFilename: '',
      pdfTitle: 'PDF Viewer ',
      dialogViewer: false,
      dialogHapus: false,
    }
  },
  created() {
    this.supplierService = new SupplierService()
    this.barcodeService = new BarcodeService()
    this.itemService = new ItemService()
  },
  mounted() {
    this.supplierService
      .get()
      .then((res) => {
        this.list_supplier = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Supplier', this)
      })
      .finally(() => (this.isLoading = false))

    this.barcodeService
      .get()
      .then((res) => {
        this.itemManuals = res.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Barcode', this)
      })
      .finally(() => (this.isLoading = false))
  },
  methods: {
    generateBarcode() {
      this.isLoadingPrint = true
      console.log('load print')
      setTimeout(() => {
        if (this.active == 0) {
          this.generateBarCodebyRcv()
        } else {
          this.generateBarCodeManual()
        }

        this.isLoadingPrint = false // Reset loading state after the process is done
      }, 100)
      // if (this.active == 0) {
      //   this.generateBarCodebyRcv()
      // } else {
      //   this.generateBarCodeManual()
      // }
    },
    generateBarCodebyRcv() {
      if (this.items.length == 0) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Barcode',
          detail: 'Pilih dan load data penerimaan terlebih dahulu.',
          life: 3000,
        })
        return
      }

      if (this.isLoadingPrint === true) {
        const doc = new jsPDF('l', 'mm', [103, 17])
        // 33 * 15
        let top = 1
        let col = [0, 35, 70]
        let monthcode = [
          '',
          'A',
          'B',
          'C',
          'D',
          'E',
          'F',
          'G',
          'H',
          'I',
          'J',
          'K',
          'L',
        ]
        let c = 0
        let itemTotal = 0
        let itemindex = 0
        this.items.forEach((item) => {
          itemTotal += item.qty_label
        })
        this.items.forEach((item) => {
          for (let index = 0; index < item.qty_label; index++) {
            itemindex += 1
            let canvas = document.createElement('canvas')
            JsBarcode(canvas, item.barcode_1, {
              format: 'CODE39',
              textAlign: 'right',
              width: 1,
              height: 50,
              text: ' ',
              textMargin: 30,
              fontSize: 30,
            })
            let makeBase64Image = canvas.toDataURL('image/png')
            let img = this.convertBase64ToImage(makeBase64Image)
            doc.addImage(
              img,
              'PNG',
              col[c] + 2,
              top + 3,
              canvas.width * 0.1,
              canvas.height * 0.1
            )
            doc.setFontSize(5)
            doc.setFont(undefined, 'normal')
            doc.text(item.description_label, col[c] + 2, top + 3)
            doc.text(item.barcode_1 + ' - ' + item.supp, col[c] + 2, top + 11)
            doc.setFont(undefined, 'bold')
            doc.text(item.dcode, col[c] + 30, top + 4)
            doc.text(monthcode[item.mcode], col[c] + 30, top + 7)
            doc.text(item.ycode, col[c] + 30, top + 10)

            doc.setFontSize(6)
            doc.text(this.formatCurrency(item.new_price), col[c] + 12, top + 13)
            doc.rect(col[c], top, 33, 15)
            if (c == 2) {
              c = 0
              if (itemindex < itemTotal) doc.addPage()
            } else {
              c += 1
            }
          }
        })

        // doc.save('barcode.pdf')

        const pdfUrl = URL.createObjectURL(doc.output('blob'))
        this.pdfSource = pdfUrl + '#toolbar=0'
        this.pdfFilename = 'item-barcode-rcv.pdf'
        this.pdfTitle = 'PDF Viewer (' + this.pdfFilename + ')'
        this.dialogViewer = true
        this.isLoadingPrint = false
      }
    },
    generateBarCodeManual() {
      if (this.itemManuals.length == 0) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Barcode',
          detail: 'Pilih item barcode terlebih dahulu.',
          life: 3000,
        })
        return
      }
      if (this.isLoadingPrint === true) {
        const doc = new jsPDF('l', 'mm', [103, 17])
        // 33 * 15
        let top = 1
        let col = [0, 35, 70]
        let c = 0
        let itemTotal = 0
        let itemindex = 0
        this.itemManuals.forEach((item) => {
          itemTotal += item.qty_label
        })
        this.itemManuals.forEach((item) => {
          for (let index = 0; index < item.qty_label; index++) {
            itemindex += 1
            let canvas = document.createElement('canvas')
            JsBarcode(canvas, item.barcode_1, {
              format: 'CODE39',
              textAlign: 'right',
              width: 1,
              height: 50,
              text: ' ',
              textMargin: 30,
              fontSize: 30,
            })
            let makeBase64Image = canvas.toDataURL('image/png')
            let img = this.convertBase64ToImage(makeBase64Image)
            doc.addImage(
              img,
              'PNG',
              col[c] + 2,
              top + 3,
              canvas.width * 0.1,
              canvas.height * 0.1
            )
            doc.setFontSize(5)
            doc.setFont(undefined, 'normal')
            doc.text(item.description, col[c] + 2, top + 3)
            doc.text(item.barcode_1 + ' - ' + item.supp, col[c] + 2, top + 11)
            doc.setFont(undefined, 'bold')

            doc.setFontSize(6)
            doc.text(this.formatCurrency(item.price), col[c] + 12, top + 13)
            doc.rect(col[c], top, 33, 15)
            if (c == 2) {
              c = 0
              if (itemindex < itemTotal) doc.addPage()
            } else {
              c += 1
            }
          }
        })

        // doc.save('barcode.pdf')

        const pdfUrl = URL.createObjectURL(doc.output('blob'))
        this.pdfSource = pdfUrl + '#toolbar=0'
        this.pdfFilename = 'item-barcode-manual.pdf'
        this.pdfTitle = 'PDF Viewer (' + this.pdfFilename + ')'
        this.dialogViewer = true
        this.isLoadingPrint = false
      }
    },
    selectReceive() {
      this.form.id = this.selectedReceive.id
      this.form.rcv_at = this.selectedReceive.rcv_at
      this.form.rcv_no = this.selectedReceive.rcv_no
      this.form.supplier = this.selectedReceive.supplier.name
    },
    searchReceive(event) {
      this.form = {}
      this.barcodeService
        .receive(event.query)
        .then((res) => {
          this.listReceive = res.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Receive', this)
        })
        .finally(() => (this.isLoading = false))
    },
    formatDate(value) {
      return formatDate(value, false)
    },
    formatCurrency(value) {
      return formatCurrency(value, 0, 0)
    },
    onReset() {
      this.itemManuals.forEach((item) => {
        this.listDeleted.push(item.id)
      })
      this.itemManuals = []
    },
    clearFilter() {
      this.listReceive = []
      this.selectedReceive = null
      this.form = {}
      this.items = []
      this.formManual = {}
      this.selectedItem = null

      this.barcodeService
        .get()
        .then((res) => {
          this.itemManuals = res.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Barcode', this)
        })
        .finally(() => (this.isLoading = false))
    },
    searchItem(event) {
      this.itemService
        .getList(event.query)
        .then((res) => {
          this.listItems = res.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Item', this)
        })
        .finally(() => (this.isLoading = false))
    },
    selectItem() {
      this.formManual.id = 0
      this.formManual.item_id = this.selectedItem.id
      this.formManual.sku = this.selectedItem.sku
      this.formManual.barcode_1 = this.selectedItem.barcode_1
      this.formManual.description = this.selectedItem.name.substr(0, 20)
      this.formManual.ubox = this.selectedItem.ubox
      this.formManual.unit_s = this.selectedItem.unit_s
      this.formManual.unit_b = this.selectedItem.unit_b
      this.formManual.supp_id = this.selectedItem.supp_id
      this.formManual.price = this.selectedItem.price
      this.formManual.qty_label = 1
    },
    onAddData() {
      if (this.formManual.item_id == null) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Barcode',
          detail: 'Pilih item terlebih dahulu.',
          life: 3000,
        })
        return
      }

      const idx = this.itemManuals.findIndex(
        (item) =>
          item.item_id === this.formManual.item_id &&
          item.supp_id === this.formManual.supp_id
      )
      if (idx !== -1) {
        const item = this.itemManuals[idx]
        this.$toast.add({
          severity: 'warn',
          summary: 'Barcode',
          detail:
            'Item ' +
            item.description +
            ' dengan supplier ' +
            item.supplier +
            ' sudah ada di list.',
          life: 3000,
        })
        return
      }

      const idn = this.list_supplier.findIndex(
        (item) => item.id === this.formManual.supp_id
      )
      if (idn !== -1) {
        const supplier = this.list_supplier[idn]
        this.formManual.supplier = supplier.name
        this.formManual.supp = supplier.supp
      }

      this.itemManuals.push(this.formManual)
      this.formManual = {}
      this.selectedItem = null
    },
    onConfirmDeletion(data) {
      this.index = data.index
      this.rowdata = data
      this.dialogHapus = true
    },
    onDeleteItem(data) {
      this.listDeleted.push(this.rowdata.id)
      this.itemManuals.splice(data.index, 1)
      // this.$toast.add({
      //   severity: 'success',
      //   summary: 'Data Item Barcode',
      //   detail: 'Data berhasil dihapus.',
      //   life: 3000,
      // })
      this.dialogHapus = false
    },
    save() {
      this.submitted = true
      // const deleted = this.id ? { deleted: this.listDeleted } : { deleted: [] }
      const auth = useAuthStore()
      const form = {
        username: auth.user.name,
        details: this.itemManuals.map((item) => {
          return {
            id: item.id,
            item_id: item.item_id,
            sku: item.sku,
            barcode_1: item.barcode_1,
            description: item.description,
            ubox: item.ubox,
            unit_s: item.unit_s,
            unit_b: item.unit_b,
            supp_id: item.supp_id,
            price: item.price,
            qty_label: item.qty_label,
            supp: item.supp,
            supplier: item.supplier,
          }
        }),
        deleted: this.listDeleted,
      }
      this.isLoadingSave = true
      this.barcodeService
        .save(form)
        .then((res) => {
          if (res.data.status === 1) {
            this.$toast.add({
              severity: 'success',
              summary: 'Barcode Item',
              detail: 'Data berhasil disimpan.',
              life: 3000,
            })
          }
        })
        .catch((err) => {
          errorHandler(err, 'Barcode Item', this)
        })
        .finally(() => {
          this.deleted = []
          this.isLoadingSave = false
        })
    },
    onTabChange() {
      if (this.active === 1) {
        // this.supplierService
        //   .getList()
        //   .then((res) => {
        //     this.list_supplier = res.data
        //   })
        //   .catch((err) => {
        //     errorHandler(err, 'Data Supplier', this)
        //   })
        //   .finally(() => (this.isLoading = false))
      }
    },
    onChecked() {
      this.change = !this.change
    },
    loadItem() {
      this.isLoading = true

      this.barcodeService
        .receiveitem(this.form.id, this.change)
        .then((res) => {
          this.items = res.data
          this.isLoading = false
        })
        .catch((err) => {
          errorHandler(err, 'Data Receive Item', this)
        })
        .finally(() => (this.isLoading = false))
    },
    convertBase64ToImage(base64) {
      const image = new Image()
      image.src = base64
      return URL.createObjectURL(this.base64ToBlob(base64))
    },
    base64ToBlob(base64) {
      const byteString = atob(base64.split(',')[1])
      const mimeString = base64.split(',')[0].split(':')[1].split(';')[0]
      const ab = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(ab)
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      return new Blob([ab], { type: mimeString })
    },
    onDownloadPdf() {
      saveAs(this.pdfSource, this.pdfFilename)
    },
    onPrintPdf() {
      const iframe = document.createElement('iframe')
      iframe.src = this.pdfSource
      iframe.style.display = 'none'
      document.body.appendChild(iframe)

      // Use onload to make pdf preview work on firefox
      iframe.onload = () => {
        iframe.contentWindow.focus()
        iframe.contentWindow.print()
      }
    },
    onTriggerHotkey(payload) {
      if (payload.keyString === 'D') {
        //
      }
    },
    onLoadingPrint() {
      setTimeout(() => {
        this.isLoadingPrint = true
      }, 500)
    },
  },
}
</script>
