import http from '../clients/Axios'

export default class BarcodeService {
  async get() {
    const res = await http.get(`/barcode`)
    return res.data
  }

  async receive(keyword = '') {
    const res = await http.get(
      // `/barcode/receive?rcv_no=${keyword}&supplier=${keyword}`
      `/receive/paginate?keyword=${keyword}`
    )

    return res.data
  }

  async receiveitem(rcv_id = 0, change = false) {
    let url = `/barcode/receiveitem?rcv_id=${rcv_id}`
    if (change == true) {
      url += `&change=true`
    }
    const res = await http.get(url)

    return res.data
  }

  async item(keyword = '') {
    const res = await http.get(
      `/barcode/item?supplier=${keyword}&sku=${keyword}&barcode_1=${keyword}&barcode_2=${keyword}&description=${keyword}`
    )
    return res.data
  }

  async save(form) {
    const res = await http.post('/barcode', form)
    return res
  }
}

